import { documentation } from 'common/links';

export const mapLinks = [
  {
    href: documentation['log-in'],
    text: 'How to login as a Web3 user',
  }, {
    href: documentation.upload,
    text: 'How to upload content',
  }, {
    href: documentation['place-order'],
    text: 'How to place an order',
  },
];
