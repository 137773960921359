import Link from 'next/link';
import cn from 'classnames';

import { Icon } from 'uikitv2/Icon';
import { ImageLinkProps } from './types';
import classes from './ImageLink.module.scss';

export const ImageLink = ({ href, className }: ImageLinkProps) => {
  return (
    <Link
      href={href}
      target="_blank"
      className={cn(classes.link, className)}
    >
      <Icon name="question" fill="none" />
    </Link>
  );
};
