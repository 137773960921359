import { FormTabs } from './types';

export const list = [
  {
    label: 'Welcome',
    value: FormTabs.welcome,
  }, {
    label: 'Releases',
    value: FormTabs.updates,
  },
];
