/* eslint-disable max-len */
import Link from 'next/link';

import getConfig from 'config';
import { discord, askQuestion } from 'common/links';
import { Box } from 'uikitv2/Box';
import { Icon } from 'uikitv2/Icon';
import { mapLinks } from './helpers';
import classes from './Welcome.module.scss';

const { NEXT_PUBLIC_DOCS } = getConfig();

export const Welcome = () => {
  return (
    <>
      <Box className={classes['title-group']} direction="column">
        <h4 className={classes.title}>Welcome to the Super Protocol AI Marketplace</h4>
        <div className={classes['sub-text']}>November 21, 2024</div>
      </Box>
      <Box direction="column">
        <p>
          Welcome to the AI Marketplace, the next big thing in the development of Super Protocol. Learn more about it&nbsp;
          <Link
            href={NEXT_PUBLIC_DOCS}
            target="_blank"
            className={classes.link}
          >
            here
          </Link>.
        </p>
        <p>Important things to know:</p>
        <ol className={classes.points}>
          <li>
            This window will contain important announcements. You can access it using the&nbsp;
            <Icon name="exclamation_grey" fill="none" className={classes['exclamation-icon']} />&nbsp;
            icon in the top menu. It will light up as&nbsp;<Icon name="exclamation_orange" fill="none" className={classes['exclamation-icon']} />&nbsp;when there are updates.
          </li>
          <li>
            Each screen and window has a&nbsp;<Icon name="question" fill="none" className={classes['question-icon']} />&nbsp;icon. It will lead you to an appropriate section in the product documentation. You can get started with the how-to guides:
            <ul className={classes.subpoints}>
              {mapLinks.map(({ href, text }, idx) => (
                <li className={classes.subpoint} key={idx}>
                  <Link
                    href={href}
                    target="_blank"
                    className={classes.link}
                  >
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li>
            To report a bug or send a suggestion, please use&nbsp;
            <Link
              href={askQuestion}
              target="_blank"
              className={classes.link}
            >
              this form
            </Link>
            . You can also contact us with questions in our&nbsp;
            <Icon name="discord_small" fill="none" className={classes['discord-icon']} />&nbsp;
            <Link
              href={discord}
              target="_blank"
              className={classes.link}
            >
              Discord channel
            </Link>
            .
          </li>
        </ol>
      </Box>
    </>
  );
};
