export const lines1 = [
  'A demo account with AOuth2 login for a quick start;',
  'Support for Intel TDX and NVIDIA GPU confidential computing;',
  'Uploading your model files to the Super Cloud or personal StorJ accounts;',
  'Deploying AI models in confidential tunnels using a variety of AI engines and settings;',
  'UI/UX redesign.',
];

export const lines2 = [
  'Publishing offers in the Marketplace from the uploaded files;',
  'Fine-tuning mode (train new layers of the original models);',
  'Support for datasets;',
  'More models and datasets available in the Marketplace;',
  'More AI engines available (and more model categories);',
  'A daytime UI theme.',
];
