import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { ImageLink } from './ImageLink';
import classes from './Title.module.scss';
import { TitleProps } from './types';

export const Title = ({
  classNameWrap, className, title, href, testId,
}: TitleProps) => (
  <Box alignItems="center" className={classNameWrap} data-testid={testId}>
    <h3 className={cn(classes.title, className)}>{title}</h3>
    <ImageLink href={href} />
  </Box>
);
