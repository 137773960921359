import { forwardRef, useMemo, memo } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { Theme } from 'uikit/common/types';
import { Spinner } from '../../Spinner';
import { Box } from '../../Box';
import classes from './Button.module.scss';
import { ButtonProps } from '../types';

export const Button = memo(forwardRef<HTMLButtonElement, ButtonProps>(({
  children = null,
  disabled = false,
  loading = false,
  wide = false,
  active = false,
  error = false,
  variant = 'base',
  href,
  className,
  onClick = () => {},
  loadingPosition = 'center',
  loadingWithText = false,
  sizeLoader,
  theme = Theme.dark,
  classNameLink,
  target,
  withBorder,
  rel,
  ...props
}, ref) => {
  const renderChildren = useMemo(
    () => (typeof children === 'function' ? children({ active, error }) : children),
    [children, active, error],
  );

  const Content = useMemo(
    () => (loading
      ? (
        <Box justifyContent="center">
          <Spinner
            as="span"
            size={sizeLoader}
            role="status"
            aria-hidden="true"
            animation="border"
            variant={variant === 'base' ? 'black' : 'base'}
            className={
              loadingPosition === 'center'
                ? classes['loading-center']
                : classes.loading
            }
          />
          {loadingWithText && renderChildren}
        </Box>
      )
      : renderChildren),
    [renderChildren, loading, sizeLoader, loadingPosition, loadingWithText, variant],
  );

  return (
    <button
      ref={ref}
      disabled={disabled || loading}
      className={cn(
        classes.root,
        classes[variant],
        classes[theme],
        {
          [classes.disabled]: disabled || loading,
          [classes.wide]: wide,
          [classes.border]: withBorder,
          [classes.active]: active,
          [classes.error]: error,
        },
        className,
      )}
      onClick={onClick}
      {...props}
    >
      {href && !disabled ? (
        <Link href={href} rel={rel} target={target} className={cn(classes.link, classNameLink)}>
          {Content}
        </Link>
      ) : Content}
    </button>
  );
}));
