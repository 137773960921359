import Link from 'next/link';

import getConfig from 'config';
import { Box } from 'uikitv2/Box';
import { lines1, lines2 } from './helpers';
import classes from './Updates.module.scss';

const { NEXT_PUBLIC_DOCS } = getConfig();

export const Updates = () => {
  return (
    <>
      <Box className={classes['title-group']} direction="column">
        <h4 className={classes.title}>AI Marketplace Phase 2 is live!</h4>
        <div className={classes['sub-text']}>November 21, 2024</div>
      </Box>
      <Box direction="column">
        <p className={classes['text-line']}>
          We have deployed the new Marketplace for AI to testnet. It includes new features and capabilities:
        </p>
        <ul className={classes.subpoints}>
          {lines1.map((line, idx) => (
            <li key={idx} className={classes.subpoint}>{line}</li>
          ))}
        </ul>
        <p className={classes['text-line-updown']}>Features coming up in the next phase:</p>
        <ul className={classes.subpoints}>
          {lines2.map((line, idx) => (
            <li key={idx} className={classes.subpoint}>{line}</li>
          ))}
        </ul>
        <p className={classes['text-line-up']}>You can learn more about these features and the roadmap&nbsp;
          <Link
            href={NEXT_PUBLIC_DOCS}
            target="_blank"
            className={classes.link}
          >
            here
          </Link>
          .
        </p>
      </Box>
    </>
  );
};
