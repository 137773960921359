import { useState, useCallback, useEffect } from 'react';

import { useAppDispatch } from 'lib/hooks';
import { setLastUpdateDate } from 'lib/features/announcements';
import { lastDateUpdated } from 'common/constants';
import { Notification } from 'uikitv2/Notification';
import { Box } from 'uikitv2/Box';
import { Tabs } from 'uikitv2/Tabs';
import { Welcome } from './Welcome';
import { Updates } from './Updates';
import { list } from './helpers';
import classes from './Announcements.module.scss';
import { FormTabs } from './types';

export const Announcements = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(FormTabs.welcome);

  const onClick = useCallback((val: FormTabs) => {
    setActiveTab(val);
  }, []);

  useEffect(() => {
    dispatch(setLastUpdateDate(lastDateUpdated));
  }, [dispatch]);

  return (
    <Box direction="column">
      <Box justifyContent="center" alignItems="center" className={classes['title-wrapper']}>
        <h3 className={classes.title}>Announcements</h3>
      </Box>
      <Box className={classes.content} direction="column">
        <Tabs {...{
          list,
          classNameItem: classes['tabs-item'],
          classNameWrap: classes['tabs-wrap'],
          active: activeTab,
          onClick,
          alignLeft: true,
        }}
        />
        {activeTab === FormTabs.welcome && <Welcome />}
        {activeTab === FormTabs.updates && <Updates />}
      </Box>
    </Box>
  );
};
